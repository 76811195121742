.main-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--neutral-dark-blue);
}

.main-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--neutral-dark-grayish-blue);
    border-radius: 1.5rem;
    width: 40%;
    position: relative;
}

.advice-header h3 {
    font-size: 0.8rem;
    letter-spacing: 4px;
    margin: 1.5rem 0 0.5rem 0;
    color: var(--primary-neon-green);
}

.advice-content {
    font-size: 28px;
    color: var(--primary-light-cyan);
    width: 80%;
    text-align: center;
}

.advice-footer {
    margin-bottom: 3.5rem;
    color: var(--primary-light-cyan);
}

.advice-footer img {
    width: 100%;
}

.advice-button button {
    background-color: var(--primary-neon-green);
    border-radius: 100%;
    aspect-ratio: 1/1;
    position: absolute;
    right: 45%;
    bottom: -10%;
    border: 1px solid transparent;
    width: 10%;
    transition: ease 500ms;

}

.advice-button button:hover {
    box-shadow: 0 0 25px 5px aquamarine;
}

@media screen and (max-width: 375px) {
    .main-card {
        width: 90%;
    }

    .advice-header h3 {
        font-size: 0.6rem;
        margin: 1rem 0 0.25rem 0;
    }

    .advice-content p {
        font-size: 20px;
    }

    .advice-footer {
        margin-bottom: 2.5rem;
    }

    .advice-footer img {
        width: 100%;
    }

    .advice-button button {
        right: 43%;
        bottom: -10%;
        width: 15%;
    }
}